export const PharmacyOperationOfficers = [
  '',
  'bernard@wellahealth.com',
  'babatunde@wellahealth.com',
  'adetoun@wellahealth.com',
  'tomi@wellahealth.com',
  'abiodun@wellahealth.com',
  'chidinma@wellahealth.com',
];

export const PharmacyPriceListMap = [
  {
    pharmacyCode: 'WHP11006',
    pharmacyName: 'Medplus',
  },
];

export const NairaSymbol = '₦';

export const noteTags = ['Onboarding', 'Cancelled', 'Payment', 'Upselling', 'Claims'];

export const cancellationReason = [
  'Delayed HMO Response',
  'Duplicate',
  'HMO Asked to Cancel',
  'Enrollee details error',
  'Late assignment',
  'PA Code Issue',
  'Pharmacy delay',
  'Pharmacy Op Issues',
  'Prescription Error',
  'Others',
];

export const TrackingStatusNotes = [
  'Awaiting HMO Feedback',
  'Confirming Enrollee information',
  'Selected pharmacy not available. Rerouting to a different pharmacy',
  'PA Code mismatch. Generating new PA Code',
  'Others',
];

export const VeriClaimJobType = {
  RelianceRobot: 'RelianceRobot',
  HygeiaRobot: 'HygeiaRobot',
  CuracelJob: 'CuracelJob',
  MedismartJob: 'MedismartJob',
};

export const SortingCenterPharmacy = 'WHP12170';

export const SortingCenterPharmacyName = 'Wefill Lagos Sorting Center';

export const PharmacyBlacklist = ['WHP10390', 'WHP10817', 'WHP11145', 'WHP11026', 'WHP10970'];

export const RelianceHmoCode = 'WHPX10002';

export const MedismartPaHMOs = ['WHPX10006', 'WHPXTest10091'];

export const DosageForms = [
  'Ointment',
  'Caplets',
  'Cream',
  'Drops',
  'Ear Drop',
  'Emulsion',
  'Eye Drop',
  'Eye Gel',
  'Eye Ointment',
  'Eye/Ear Drop',
  'Face wash',
  'Gel',
  'Granules',
  'Gummies',
  'Infusion',
  'Inhalation Solution',
  'Inhaler',
  'Injection',
  'Insole',
  'Intravenous (IV)',
  'Liniment',
  'Lotion',
  'Lozenges',
  'Nasal Drop',
  'Nasal Spray',
  'Oral Drop',
  'Oral Solution',
  'Ovule',
  'Paste',
  'Pastilles',
  'Patch',
  'Pessary',
  'Plaster',
  'Powder',
  'Powder Spray',
  'Pump spray',
  'Shampoo',
  'Sling',
  'Soap',
  'Solution',
  'Spray',
  'SC Injection',
  'Suppository',
  'Suspension',
  'Syrup',
  'Tablets',
  'Vaginal Tablets',
  'NIL',
];

export const DrugClass = [
  'ANAESTHETIC',
  'ANALGESIC ',
  'ANTACID',
  'ANTHELMINTIC',
  'ANTI-ALLERGY',
  'ANTI-DIABETIC',
  'ANTI-HYPERTENSIVE',
  'ANTI-INFLAMMATORY',
  'ANTI-PARASITIC',
  'ANTI-ULCER',
  'ANTIBIOTIC',
  'ANTIFUNGAL',
  'ANTIMALARIAL',
  'ANTIPLATELET',
  'ANTIPYRETIC',
  'ANTIVIRAL',
  'CARDIOVASCULAR',
  'CHEMOTHERAPEUTIC',
  'CONSUMABLES',
  'COUGH AND COLD',
  'GASTROINTESTINAL',
  'HORMONAL',
  'IMMUNOLOGICAL',
  'IV FLUIDS',
  'MEDICAL AID',
  'MEDICAL DEVICE',
  'NEUROLOGIC',
  'OBSTETRICS ',
  'OPTHALMIC',
  'OTHERS',
  'RESPIRATORY',
  'SEXUAL HEALTH',
  'STEROID',
  'SUPPLEMENT',
  'TOPICAL',
  'UROLOGIC',
  'VACCINE',
];
